import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  addEmployee: async ({
                        id,
                        firstName,
                        secondName,
                        lastName,
                        userName,
                        email,
                        secondaryEmail,
                        homePhone,
                        county_id,
                        workPhone,
                        mobilePhone,
                        mailAddress,
                        mailCity,
                        mailState,
                        voterAddress,
                        voterZip,
                        voterCity,
                        voterState,
                        inelegible,
                        vuid,
                        mailZip,
                        birthDate,
                        employeeRoleId,
                        reportsTo,
                        disabled_email_notification_ba,
                        disabled_sms_notification_ba,
                        voter_county_id,
                        eligibility_detail_id
                      }) => {
    await HTTP.post(
      'employees',
      {
        id,
        firstName,
        secondName,
        lastName,
        userName,
        email,
        secondaryEmail,
        county_id,
        homePhone,
        workPhone,
        mobilePhone,
        mailAddress,
        mailCity,
        mailState,
        voterAddress,
        voterZip,
        voterCity,
        voterState,
        inelegible,
        vuid,
        mailZip,
        birthDate,
        employeeRoleId,
        reportsTo,
        disabled_email_notification_ba,
        disabled_sms_notification_ba,
        voter_county_id,
        eligibility_detail_id
      },
      { headers: helper.setHeaders() }
    )
  },
  addEmployeeByAdmin: async ({
                               id,
                               firstName,
                               secondName,
                               lastName,
                               userName,
                               email,
                               secondaryEmail,
                               homePhone,
                               workPhone,
                               mobilePhone,
                               mailAddress,
                               mailCity,
                               county_id,
                               mailState,
                               voterAddress,
                               voterZip,
                               voterCity,
                               voterState,
                               vuid,
                               mailZip,
                               birthDate,
                               employeeRoleId,
                               reportsTo,
                               member_id,
                               inelegible,
                               location,
                               department,
                               voter_county_id,
                               eligibility_detail_id
                             }) => {
    await HTTP.post(
      'employees',
      {
        id,
        firstName,
        secondName,
        lastName,
        userName,
        email,
        secondaryEmail,
        homePhone,
        workPhone,
        mobilePhone,
        mailAddress,
        mailCity,
        county_id,
        mailState,
        voterAddress,
        voterZip,
        voterCity,
        voterState,
        vuid,
        mailZip,
        birthDate,
        employeeRoleId,
        reportsTo,
        member_id,
        inelegible,
        location,
        department,
        eligibility_detail_id,
        voter_county_id
      },
      { headers: helper.setHeaders() }
    )
  },
  editEmployee: async ({
                         rescreen,
                         id,
                         firstName,
                         secondName,
                         lastName,
                         userName,
                         email,
                         secondaryEmail,
                         homePhone,
                         workPhone,
                         mobilePhone,
                         mailAddress,
                         county_id,
                         mailCity,
                         mailState,
                         voterAddress,
                         voterZip,
                         voterCity,
                         voterState,
                         inelegible,
                         vuid,
                         mailZip,
                         birthDate,
                         employeeRoleId,
                         reportsTo,
                         disabled_email_notification_ba,
                         disabled_sms_notification_ba
                       }) => {
    try {
      const res = await HTTP.put(
        'employees/' + id,
        {
          rescreen,
          id,
          firstName,
          secondName,
          lastName,
          userName,
          email,
          secondaryEmail,
          homePhone,
          workPhone,
          mobilePhone,
          county_id,
          mailAddress,
          mailCity,
          mailState,
          voterAddress,
          voterZip,
          voterCity,
          voterState,
          inelegible,
          vuid,
          mailZip,
          birthDate,
          employeeRoleId,
          reportsTo,
          disabled_email_notification_ba,
          disabled_sms_notification_ba
        },
        {
          headers: helper.setHeaders()
        }
      )
      return res.data.success
    } catch (e) {
      await helper.errorHandler(e.response)
      return false
    }
  },
  editEmployeeByMemberWithoutRescreen: async ({
                                                rescreen,
                                                id,
                                                firstName,
                                                secondName,
                                                lastName,
                                                userName,
                                                email,
                                                secondaryEmail,
                                                homePhone,
                                                workPhone,
                                                mobilePhone,
                                                mailAddress,
                                                county_id,
                                                mailCity,
                                                mailState,
                                                voterAddress,
                                                voterZip,
                                                voterCity,
                                                voterState,
                                                inelegible,
                                                vuid,
                                                mailZip,
                                                birthDate,
                                                employeeRoleId,
                                                reportsTo,
                                                disabled_email_notification_ba,
                                                disabled_sms_notification_ba
                                              }) => {
    try {
      const res = await HTTP.post(
        'employees/update-without-re-screen/' + id,
        {
          rescreen,
          id,
          firstName,
          secondName,
          lastName,
          userName,
          email,
          secondaryEmail,
          homePhone,
          workPhone,
          mobilePhone,
          county_id,
          mailAddress,
          mailCity,
          mailState,
          voterAddress,
          voterZip,
          voterCity,
          voterState,
          inelegible,
          vuid,
          mailZip,
          birthDate,
          employeeRoleId,
          reportsTo,
          disabled_email_notification_ba,
          disabled_sms_notification_ba
        },
        {
          headers: helper.setHeaders()
        }
      )
      return res.data.success
    } catch (e) {
      await helper.errorHandler(e.response)
      return false
    }
  },
  editEmployeeByAdmin: async (data) => {
    try {
      const res = await HTTP.put(
        'employees/' + data.id,
        data,
        {
          headers: helper.setHeaders()
        }
      )
      await helper.errorHandler(res)
      return res.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return false
    }
  },
  editEmployeeByAdminWithoutRescreen: async (data) => {
    try {
      const res = await HTTP.post(
        'employees/update-without-re-screen/' + data.id,
        data,
        {
          headers: helper.setHeaders()
        }
      )
      await helper.errorHandler(res)
      return res.data.success
    } catch (e) {
      await helper.errorHandler(e.response)
      return false
    }
  },
  getEmployees: async (params) => {
    const response = await HTTP.get('employees', {
      params: params,
      headers: helper.setHeaders()
    })
    return response.data
  },
  getAllEmployees: async (params) => {
    const response = await HTTP.get('business/employees', {
      params: params,
      headers: helper.setHeaders()
    })
    return response.data
  },
  getEmployeeVoting: async () => {
    const response = await HTTP.get('/employee/get/voutings', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getSuspendedEmployees: async () => {
    const response = await HTTP.get('employees/suspended', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getAllSuspendedEmployees: async () => {
    const response = await HTTP.get('business/employees/suspended', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getEmployeesByMember: async ({ id }) => {
    const response = await HTTP.get('members/' + id + '/employees', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getSuspendedEmployeesByMember: async ({ id }) => {
    const response = await HTTP.get('members/' + id + '/employees/suspended', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getEmployeesRoles: async () => {
    const response = await HTTP.get('employees-roles', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  deleteEmployee: async ({ id }) => {
    await HTTP.delete('employees/' + id, {
      headers: helper.setHeaders()
    })
  },
  deleteEmployees: async ({ employees }) => {
    await HTTP.put(
      'employees/delete-multiple',
      { employees },
      {
        headers: helper.setHeaders()
      }
    )
  },
  enableDisableEmployee: async ({ id }) => {
    await HTTP.put(
      'employees/enable-disable',
      { id },
      {
        headers: helper.setHeaders()
      }
    )
  },
  enableDisableEmployees: async ({ employees }) => {
    await HTTP.put(
      'employees/enable-disable-multiple',
      { employees },
      {
        headers: helper.setHeaders()
      }
    )
  },
  getRequestHistory: async ({ id }) => {
    const response = await HTTP.get('/employees/' + id + '/request-history', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getOpportunitiesByEmployee: async (id, filters) => {
    const response = await HTTP.get('/employees/' + id + '/openOpportunities', {
      params: filters,
      headers: helper.setHeaders()
    })
    return response.data
  },
  submitVotingRequest: async ({
                                electionId,
                                employeeId,
                                voteDate,
                                user_who_approved_id,
                                is_approved = false
                              }) => {
    try {
      return await HTTP.post(
        'vote-requests',
        {
          employeeId,
          electionId,
          voteDate,
          user_who_approved_id,
          is_approved
        },
        { headers: helper.setHeaders() }
      )
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  submitVolunteerRequest: async ({ eventDayId, employeeId }) => {
    await HTTP.post(
      '/employees/' + employeeId + '/store-volunteer',
      {
        eventDayId
      },
      { headers: helper.setHeaders() }
    )
  },
  hasVoteRequest: async ({ id, voteRequestId }) => {
    const response = await HTTP.get(
      'employees/' + id + 'voting-request/' + voteRequestId,
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  deleteRequest: async ({ id, requestId, requestType }) => {
    await HTTP.delete(
      '/employees/' + id + '/volunteering-requests/' + requestType + '/' + requestId,
      {
        headers: helper.setHeaders()
      }
    )
  },
  getModules: async ({ id }) => {
    const response = await HTTP.get('/employees/' + id + '/member-modules', {
      headers: helper.setHeaders()
    })
    return response.data
  },

  approveRequests: async ({ requestIds, employeeId }) => {
    await HTTP.post(
      'employees/' + employeeId + '/approve-requests',
      { requestIds },
      {
        headers: helper.setHeaders()
      }
    )
  },
  rejectRequests: async ({ requestIds, employeeId }) => {
    await HTTP.post(
      'employees/' + employeeId + '/reject-requests',
      { requestIds },
      {
        headers: helper.setHeaders()
      }
    )
  },
  pendingRequests: async ({ id }) => {
    const response = await HTTP.get('employees/' + id + '/pending-requests', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  historicalRequests: async ({ id }) => {
    const response = await HTTP.get(
      'employees/' + id + '/historical-requests',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  votingStatisticsCards: async ({ employeeId, electionId }) => {
    const response = await HTTP.get(
      'employees/' + employeeId + '/voting-statistics/' + electionId + '/cards',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  votingStatisticsElectionData: async ({ employeeId, electionId }) => {
    const response = await HTTP.get(
      'employees/' +
      employeeId +
      '/voting-statistics/' +
      electionId +
      '/election-data',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  scheduledCommunicationPlan: async (employeeId) => {
    const response = await HTTP.get(
      'employees/' + employeeId + '/scheduled-communication-plan',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getEmployeesToReport: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/employees-to-report',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getAllEmployeesToReport: async () => {
    const response = await HTTP.get(
      'business/employees-to-report',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  updateEmployeeFile: async (file, election_id) => {
    try {
      let formData = new FormData()
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      formData.append('file', file)
      const response = await HTTP.post(
        `member-upload-employees-file/` + election_id,
        formData,
        {
          headers: headers
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updateDistrictFile: async (file) => {
    try {
      let formData = new FormData()
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      formData.append('file', file)
      const response = await HTTP.post(
        `admin-upload-districts-file`,
        formData,
        {
          headers: headers
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  downloadUpdateFile: async () => {
    try {
      const response = await HTTP.get('downloadExcelUpdateEmployee', {
        headers: helper.setHeaders()
      })
      location.href = response.data
      return true
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getVoterGuidesByEmployeeId: async (employeeId, electionId) => {
    const response = await HTTP.get(
      'employees/' + employeeId + '/voters-guides/' + electionId,
      { headers: helper.setHeaders() }
    )
    return response.data
  },
  addRegistreRequest: async ({ employeeId, item, state }) => {
    const response = await HTTP.post(
      'employees/' + employeeId + '/registration-request',
      { item, state },
      { headers: helper.setHeaders() }
    )
    return response.data.data
  },
  resendRegistration: async (employeeId) => {
    return await HTTP.get(
      'employees/resend-verification-email/' + employeeId,
      { headers: helper.setHeaders() }
    )
  }
}
